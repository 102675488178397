import { Card } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

export default function Banner1() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Default number of slides to show
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
       
  };
  return (
    <div  className='home1'>
    <Slider {...settings}>
      <div className="backgroundimage1">
        <div class="home-slider-img-wrapper">
                  <div class="home-slider-content-wrapper">
                     <div class="container sidepdd">
                        <div class="row d-flex align-items-center" style={{height: "296px"}}>
                        <div class="col-lg-12">
                          
                          <div class="home-slider-title" style={{marginTop:'0'}}>
                          Best Music and Video Company <span>in India</span>
                          </div>
                          <div class="home-slider-desc">"Music can change the world". Bainsla Music is <br/>India's No.1 Music label company,Provide Rajasthani genre of 'Rasiya' songs, <br/>believes in bringing world close together through its music,<br/>We provide YouTube CMS/MCN Services. </div>
                          <Link to="/about" class="button button-primary mt-2">Read More </Link>
                       </div>
                           <div class="col-lg-6 ">
                              {/* <img src="img/video-img.png" class="img-fluid  " alt="mlm software company"/> */}
                           </div>
                           
                        </div>
                     </div>
                  </div>
               </div>
               </div>
               <div className="backgroundimage2">
        <div class="home-slider-img-wrapper">
                  <div class="home-slider-content-wrapper">
                     <div class="container sidepdd">
                        <div class="row d-flex align-items-center" style={{height: "296px"}}>
                        <div class="col-lg-12">
                          
                          <div class="home-slider-title" style={{marginTop:'0'}}>
                          Best Music and Video Company <span>in India</span>
                          </div>
                          <div class="home-slider-desc">"Music can change the world". Bainsla Music is <br/>India's No.1 Music label company,Provide Rajasthani genre of 'Rasiya' songs,<br/> believes in bringing world close together through its music.<br/>We provide service like Channel Promotion & Management. </div>
                          <Link to="/about" class="button button-primary mt-2">Read More </Link>
                       </div>
                           <div class="col-lg-6 ">
                              {/* <img src="img/video-img.png" class="img-fluid  " alt="mlm software company"/> */}
                           </div>
                           
                        </div>
                     </div>
                  </div>
               </div>
               </div>  
               <div className="backgroundimage3">
        <div class="home-slider-img-wrapper">
                  <div class="home-slider-content-wrapper">
                     <div class="container sidepdd">
                        <div class="row d-flex align-items-center" style={{height: "296px"}}>
                        <div class="col-lg-6">
                          
                          <div class="home-slider-title" style={{marginTop:'0'}}>
                          Best Music and Video Company <span>in India</span>
                          </div>
                          <div class="home-slider-desc">"Music can change the world". Bainsla Music is India's No.1 Music label company,Provide Rajasthani genre of 'Rasiya' songs, believes in bringing world close together through its music .<br/> We provide sevices like Audio Video Distribution and many more... </div>
                          <Link to="/about" class="button button-primary mt-2">Read More </Link>
                       </div>
                           <div class="col-lg-6 ">
                              {/* <img src="img/video-img.png" class="img-fluid  " alt="mlm software company"/> */}
                           </div>
                           
                        </div>
                     </div>
                  </div>
               </div>
               </div>     
              
    </Slider>
    </div>
  );
}