import React from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import ContactUs from './Contactus';
import Banner from './Banner';
import Map from './Map';

function Contact() {
  return (
    <div>
        <Header/>
        <Banner/>
        <ContactUs/>
        <Map/>
        <Footer/>
    </div>
   
  );
}

export default Contact;