import React from 'react'

function FristPage() {
  return (
    <div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 col-sm-6 col-md-4'>
            <div className='dashbord'>
              TOPADD
            </div>
          </div>
          <div className='col-12 col-sm-6 col-md-4'>
            <div className='dashbord'>
              BOTTOMADD
            </div>
          </div>
          <div className='col-12 col-sm-6 col-md-4'>
            <div className='dashbord'>
              VIDEO
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FristPage
