import React from 'react';
import { Link } from 'react-router-dom';
function TermsComponent() {
  return (
    <div>
<div class="terms">
  <div className='container'>
    <div className='row'>
    {/* <div class="images-al-set">
   <img class="img-fluid"src="images/coming-soon.png"/>

  </div> */}
 <h3 className='gradient-text'> Terms and Conditions for Bainsla Music</h3>

<h4 className='gradient-text'>Welcome to Bainsla Music!</h4>

<p>These terms and conditions outline the rules and regulations for the use of Bainsla Music's Website, located at <Link to ="/">Bainsla Music/https://bainslamusic.com/</Link></p>

<p>By accessing this website, we assume you accept these terms and conditions. Do not continue to use Bainsla Music if you do not agree to take all of the terms and conditions stated on this page.</p>

<h4>1. License</h4>
<p>Unless otherwise stated, Bainsla Music and/or its licensors own the intellectual property rights for all material on Bainsla Music. All intellectual property rights are reserved. You may access this from Bainsla Music for your own personal use subjected to restrictions set in these terms and conditions</p>.

<h4>You must not:</h4>

<p>Republish material from Bainsla Music</p>
<p>Sell, rent, or sub-license material from Bainsla Music</p>
<p>Reproduce, duplicate, or copy material from Bainsla Music</p>
<p>Redistribute content from Bainsla Music</p>
<h4>2. User Content</h4>
<p>In these terms and conditions, "Your Content" shall mean any audio, video, text, images, or other material you choose to display on this Website. With respect to Your Content, by displaying it, you grant Bainsla Music a non-exclusive, worldwide, irrevocable, royalty-free license to use, reproduce, adapt, publish, translate, and distribute it in any and all media</p>.

<p>Your Content must be your own and must not be infringing on any third party’s rights. Bainsla Music reserves the right to remove any of Your Content from this Website at any time, and for any reason, without notice.</p>

<h4>3. No warranties</h4>
<p>This Website is provided "as is," with all faults, and Bainsla Music expresses no representations or warranties, of any kind related to this Website or the materials contained on this Website. Also, nothing contained on this Website shall be interpreted as advising you.</p>

<h4>4. Limitation of liability</h4>
<p>In no event shall Bainsla Music, nor any of its officers, directors, and employees, be held liable for anything arising out of or in any way connected with your use of this Website, whether such liability is under contract, tort, or otherwise, and Bainsla Music, including its officers, directors, and employees shall not be held liable for any indirect, consequential, or special liability arising out of or in any way related to your use of this Website.</p>

<h4>5. Indemnification</h4>
<p>You hereby indemnify to the fullest extent Bainsla Music from and against any and/or all liabilities, costs, demands, causes of action, damages, and expenses arising in any way related to your breach of any of the provisions of these terms and conditions.</p>

<h4>6. Severability</h4>
<p>If any provision of these terms and conditions is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.</p>

<h4>7. Variation of Terms</h4>
<p>Bainsla Music is permitted to revise these terms and conditions at any time as it sees fit, and by using this Website, you are expected to review these terms and conditions on a regular basis.</p>

<h4>8. Assignment</h4>
<p>The Bainsla Music is allowed to assign, transfer, and subcontract its rights and/or obligations under these terms and conditions without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these terms and conditions.
</p>
<h4>9. Entire Agreement</h4>
<p>These terms and conditions constitute the entire agreement between Bainsla Music and you in relation to your use of this Website, and supersede all prior agreements and understandings.</p>

<h4>10. Governing Law & Jurisdiction</h4>
<p>These terms and conditions will be governed by and interpreted in accordance with the laws of the State of Rajasthan, and you submit to the non-exclusive jurisdiction of the state and federal courts located in Rajasthan for the resolution of any disputes.</p>
    </div>
  </div>

  </div>
</div>
   
  );
}

export default TermsComponent;