// import React from 'react';
// import { Link } from 'react-router-dom';
// function Network() {
//   return (
//     <div>
//         <div class="container">
//     <h2 className='gradient-text'>KKHS YOUTUBE NETWORK</h2>
//     <div class="row">
//                     <div class="col-md-1 col-lg-1 counter-column">
//                     </div>
//                     <div class="col-md-4 col-lg-4 counter-column">
//                         <div class="dot">
//                             <img src="img/youtube-logo.png"/>
//                             <span class="counter"><Link to='https://bainslamusic.com/'><img src="img/youtube-logo1.png"/></Link>
// </span>
//                         </div>
//                         <h3>564,109 VIDEOS IN NETWORK</h3>
//                     </div>
//                     <div class="col-md-2 col-lg-2 counter-column">
//                     </div>
//                     <div class="col-md-4 col-lg-4 counter-column">
//                         <div class="dot">
//                             <img src="img/youtube-logo.png"/>
//                             <span class="counter">24,301,428,764</span>
//                         </div>
//                         <h3>24 BILLION HOURS SERVED</h3>
//                     </div>
//                     <div class="col-md-1 col-lg-1 counter-column">
//                     </div>
//                 </div>
//      </div>
//      </div>
//   );
// }

// export default Network




// import { Card } from "antd";
// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import Slider from "react-slick";

// export default function Network() {


//   var settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 4, // Default number of slides to show
//     slidesToScroll: 1,
//     // autoplay: true,
//     // autoplaySpeed: 2000,
//     responsive: [
//       {
//         breakpoint: 1024, // Adjust these breakpoints as needed
//         settings: {
//           slidesToShow: 3,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 2,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//     ],
//   };

//   return (
    
//     <div className="container network">
//       <h2 className="gradient-text">Trending Videos</h2>
//       <Card>
//       <Slider {...settings}>
       
//         <div className="counter-column">
//         <div class="dot">
//             <div className="dotclass">
//             <img src="img/youtube-logo.png"/>
//             <span class="counter"><Link to='https://bainslamusic.com/'><img src="img/youtube-logo1.png"/></Link></span>
//             </div>
//         </div>
//         </div>
//         <div className="counter-column">
//         <div class="dot">
//             <div className="dotclass">
//             <img src="img/youtube-logo.png"/>
//             <span class="counter"><Link to='https://bainslamusic.com/'><img src="img/youtube-logo1.png"/></Link></span>
//             </div>
//         </div>
//         </div>
//         <div className="counter-column">
//         <div class="dot">
//             <div className="dotclass">
//             <img src="img/youtube-logo.png"/>
//             <span class="counter"><Link to='https://bainslamusic.com/'><img src="img/youtube-logo1.png"/></Link></span>
//             </div>
//         </div>
//         </div>
//         <div className="counter-column">
//         <div class="dot">
//             <div className="dotclass">
//             <img src="img/youtube-logo.png"/>
//             <span class="counter"><Link to='https://bainslamusic.com/'><img src="img/youtube-logo1.png"/></Link></span>
//             </div>
//         </div>
//         </div>
//         <div className="counter-column">
//         <div class="dot">
//             <div className="dotclass">
//             <img src="img/youtube-logo.png"/>
//             <span class="counter"><Link to='https://bainslamusic.com/'><img src="img/youtube-logo1.png"/></Link></span>
//             </div>
//         </div>
//         </div>
//         <div className="counter-column">
//         <div class="dot">
//             <div className="dotclass">
//             <img src="img/youtube-logo.png"/>
//             <span class="counter"><Link to='https://bainslamusic.com/'><img src="img/youtube-logo1.png"/></Link></span>
//             </div>
//         </div>
//         </div>
//         <div className="counter-column">
//         <div class="dot">
//             <div className="dotclass">
//             <img src="img/youtube-logo.png"/>
//             <span class="counter"><Link to='https://bainslamusic.com/'><img src="img/youtube-logo1.png"/></Link></span>
//             </div>
//         </div>
//         </div>
  
     
                        
//       </Slider>
//       </Card>
//     </div>
//   );
// }




import { Card } from "antd";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

export default function Network() {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await fetch("channallink.txt");
        const text = await response.text();
        const videoArray = text.split("\n").filter((url) => url.trim() !== "");
        setVideos(videoArray);
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };

    fetchVideos();
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="container network">
      <h2 className="gradient-text">Our Channels</h2>
      <Card>
        <Slider {...settings}>
          {videos.map((video, index) => (
            <div key={index} className="counter-column">
              <div className="dot">
                <div className="dotclass">
                  <img src={`img/youtube-logo${index + 1}.png`} alt={`YouTube logo ${index + 1}`} />
                  <span className="counter">
                  <Link to={video}>
                    <img src="img/youtube-logo.png" alt="YouTube logo" />
                 </Link>
                  </span>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </Card>
    </div>
  );
}
