import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const Video = () => {
  const serverUrl = "https://en.kkhs.in/";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileList, setFileList] = useState([]);
  const navigate = useNavigate();

  const [isModalOpenedit, setIsModalOpenedit] = useState(false);
  const [isModalOpendlt, setIsModalOpendlt] = useState(false);

  const [video, setVideo] = useState({
    videoLink: "",
  });

  const [getVideo, setGetVideo] = useState([]);

  const [updateId, setUpdateId] = useState();
  const [videoUpdate, setVideoUpdate] = useState({
    videoLink: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setVideo((prevVideo) => ({
      ...prevVideo,
      [name]: value,
    }));
  };

  const handleData = async () => {
    try {
      const response = await fetch(`${serverUrl}api/upload/video`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(video),
      });

      if (response.ok) {
        alert("Video Uploaded");
        setVideo({
          videoLink: "",
        });
        setIsModalOpen(false);
        getData();
      } else if (response.status === 400) {
        alert("Invalid Url");
      } else {
        alert("Please Try Again");
      }
    } catch (error) {
      console.error(error);
      alert("An error occurred. Please try again.");
    }
  };

  const getData = async () => {
    try {
      const response = await fetch(`${serverUrl}api/upload/get-video`, {
        method: "GET",
      });

      if (!response.ok) {
        alert("Please Try Again")
      }

      const data = await response.json();
      setGetVideo(data.videos);
    } catch (error) {
      console.error(error);
      alert("An error occurred. Please try again.");
    }
  };

  const delData = async (id) => {
    try {
      const response = await fetch(`${serverUrl}api/upload/del-video/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        }
      });

      if (response.ok) {
        alert(`This value has been Deleted`);
        getData();
      } else {
        alert("Error In delete");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showModaledit = (videoId) => {
    const videoToEdit = getVideo.find(video => video._id === videoId);
    setUpdateId(videoId);
    setVideoUpdate({
      videoLink: videoToEdit.videoLink,
    });
    setIsModalOpenedit(true);
  };

  const handleUpdateChange = (event) => {
    const { name, value } = event.target;
    setVideoUpdate((prevVideo) => ({
      ...prevVideo,
      [name]: value,
    }));
  };

  const updateVideo = async (id) => {
    try {
      const response = await fetch(`${serverUrl}api/upload/update-video/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(videoUpdate)
      });

      if (response.ok) {
        alert(`This value has been Updated`);
        setVideoUpdate({
          videoLink: "",
        });
        setIsModalOpenedit(false);
        getData();
      } else {
        alert("Invalid Url");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handledelete = async () => {
    try {
      const response = await fetch(`${serverUrl}api/upload/del-video/${updateId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        }
      });

      if (response.ok) {
        alert(`This value has been Deleted`);
        setIsModalOpendlt(false);
        getData();
      } else {
        alert("Error In delete");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showModalview = () => {
    navigate("/insta");
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Button type="primary" onClick={() => setIsModalOpen(true)}>
        ADD VIDEO
      </Button>
      <Modal title="ADD Video" visible={isModalOpen} onOk={() => setIsModalOpen(false)} onCancel={() => setIsModalOpen(false)}>
        <div className="topadd">
          <div>
            <label>Video Link</label>
            <br />
            <input type="text" name="videoLink" onChange={handleChange} value={video.videoLink} />
            <br />
            <button onClick={handleData} className='primary'>Submit</button>
          </div>
        </div>
      </Modal>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Video Url Id</th>
              <th>Date & Time</th>
              <th>Updated Date & Time</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {[...getVideo].reverse().map((video, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{video.videoLink}</td>
                <td>{`${video.date} & ${video.time}`}</td>
                <td>{`${video.dateUpdated || 'No Updated'} & ${video.timeUpdated || 'No Updated'}`}</td>
                <td>
                  <Button type="primary" onClick={() => showModaledit(video._id)}>
                    <EditOutlined />
                  </Button> &nbsp; &nbsp;
                  <Button type="primary" onClick={() => delData(video._id)}>
                    <DeleteOutlined />
                  </Button>&nbsp; &nbsp;
                  <Button type="primary" onClick={() => window.open(`https://www.youtube.com/embed/${video.videoLink}`, '_blank')}>
                    <EyeOutlined />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Modal title="EDIT" visible={isModalOpenedit} onOk={() => setIsModalOpenedit(false)} onCancel={() => setIsModalOpenedit(false)}>
          <div className="topadd">
            <div>
              <label>Video Link</label>
              <br />
              <input type="text" name="videoLink" value={videoUpdate.videoLink} onChange={handleUpdateChange} />
              <br />
              <button onClick={() => updateVideo(updateId)} className='primary'>Update</button>
            </div>
          </div>
        </Modal>
        <Modal title="DELETE" visible={isModalOpendlt} onOk={() => setIsModalOpendlt(false)} onCancel={() => setIsModalOpendlt(false)}>
          <p>Are You Sure to Delete this</p>
          <button type="primary" className='primary' onClick={handledelete}>Delete</button>
        </Modal>
      </div>
    </>
  );
};

export default Video;
