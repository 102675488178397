import React from 'react';
import Infotabs from './Infotabs';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';

function Services() {
  return (
<div>
    <Header/>


    <div className='detailsteam'>
  <div className='container'>
    <div className='row'>
    <div className=''>
        <div className='teamimg'>
        <img src="img/icon3.jpg" style={{width:"30%"}}/>
        </div>
     
    </div>
    <div className=''>

<h3 className='gradient-text'>YouTube CMS/MCN Services</h3>
<p>We always provide people a complete and fastest solution focused of your project.</p>
 <div className=''>
    <h3>Experience & Activities</h3>
    <p>CMS/MCN services provide tools to manage and protect copyrighted content on YouTube. They use Content ID, a digital fingerprinting system, to identify and manage copyrighted content uploaded by users.</p>

    <h3>Audience Development</h3>
    <p>CMS/MCNs help creators monetize their content through various means, such as advertising revenue, sponsorships, and merchandise sales. They often offer better revenue sharing deals compared to what creators can access independently.</p>

    <h3>Monetization</h3>
    <p>These services may provide assistance with audience growth strategies, including channel optimization, audience engagement techniques, and cross-promotion with other creators within their network..</p>
   
    <h3>Creator Support</h3>
    <p>Many CMS/MCNs offer personalized support to creators, including access to dedicated account managers, resources for improving content quality, and assistance with YouTube policies and guidelines.</p>

    <h3>Access to Tools and Analytics:</h3>
    <p>CMS/MCNs often provide creators with access to advanced analytics and optimization tools to help them understand their audience better and improve their content strategy.</p>

    <h3>Brand Partnerships</h3>
    <p>Some CMS/MCNs facilitate brand partnerships and sponsorship deals for creators, helping them to monetize their content beyond traditional advertising revenue.</p>

    <h3>Legal and Copyright Assistance</h3>
    <p>CMS/MCNs may offer legal support and assistance with copyright issues, including handling copyright disputes and providing guidance on fair use and licensing agreements.</p>
 </div>
    </div>
    </div>
  </div>
  </div>
  <Footer/>
  </div>
    // <div className='servicepart'>
    //     <div className='container '>
    //        <div className='row'>
    //        <h2 class="gradient-text">Our Services</h2>
    //        <div className='col-md-6 col-lg-4 col-12'>
    //         <div className='myservice height-setup'>
    //             <img src="img/digital.jpg" style={{width:"100%"}}></img>
    //             <h4>Sync Licensing</h4>
    //             <p>Securing placements for music in films, TV shows, commercials, video games, and other multimedia projects.</p>
    //        </div>
    //        </div>
    //        <div className='col-md-6 col-lg-4 col-12'>
    //        <div className='myservice height-setup'>
    //             <img src="img/playcount.jpg" style={{width:"100%"}}></img>
    //             <h4>Soundtrack and Composition Services</h4>
    //             <p>Creating original music compositions, soundtracks, and scores for films, TV shows, commercials, and other media projects.</p>
    //         </div>
          
    //        </div>
    //        <div className='col-md-6 col-lg-4 col-12'>
    //        <div className='myservice height-setup'>
    //            <img src="img/digital-mixer.jpg" style={{width:"100%"}}></img>
    //            <h4>Marketing and Promotion</h4>
    //            <p> Developing marketing strategies, creating promotional materials, and running campaigns to build awareness and reach audiences.</p>        
    //        </div>
    //        </div>

    //        <div className='col-md-6 col-lg-4 col-12'>
    //         <div className='myservice height-setup'>
    //             <img src="img/digital.jpg" style={{width:"100%"}}></img>
    //             <h4>Sync Licensing</h4>
    //             <p>Securing placements for music in films, TV shows, commercials, video games, and other multimedia projects.</p>
    //        </div>
    //        </div>
    //        <div className='col-md-6 col-lg-4 col-12'>
    //         <div className='myservice height-setup'>
    //             <img src="img/digital.jpg" style={{width:"100%"}}></img>
    //             <h4>Sync Licensing</h4>
    //             <p>Securing placements for music in films, TV shows, commercials, video games, and other multimedia projects.</p>
    //        </div>
    //        </div>
    //        <div className='col-md-6 col-lg-4 col-12'>
    //         <div className='myservice height-setup'>
    //             <img src="img/digital.jpg" style={{width:"100%"}}></img>
    //             <h4>Sync Licensing</h4>
    //             <p>Securing placements for music in films, TV shows, commercials, video games, and other multimedia projects.</p>
    //        </div>
    //        </div>
           

    //         </div>
    //     </div>
  

    //  </div>
  );
}

export default Services