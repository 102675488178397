import React from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import Team from './Team';
import Banner from './Banner';
import Aboutdetail from './Aboutdetail';
import Aboutsite from './Aboutsite';
function About() {
  return (
    <div>
        <Header/>
        <Banner/>
        <Aboutsite/>
        {/* <Aboutdetail/> */}
  
        <Team />
        <Footer/>
     </div>
  );
}

export default About