// import { Card } from 'antd';
// import React from 'react';
// function Infotabs() {
//   return (
//     <div>
//      <div className='container-fluid'>
//         <div className='container'>
//             <div className='row cardtabs'>
             
//                 <Card  className="ban1" style={{ width: 270 }}>
//                     <div className='ban11'>
//                     <p>Music Technology and Software Development</p>
//                     <span>Developing innovative music-related software, apps, and tools for musicians, producers, and    audio engineers.</span>
//                     </div>
//                 </Card>  
//                 <Card className="ban2" style={{ width: 270 }}>
//                   <p>Music Production</p>
//                   <span>Recording, mixing, and mastering music tracks in professional studios.</span>
//                 </Card> 
                 
//                 <Card className="ban3" style={{ width: 270 }}>
//                   <p>Live Event Production</p>
//                   <span>Organizing concerts, music festivals, and other live events, including venue booking, event promotion, and logistics management</span>
//                 </Card> 

//                 <Card className="ban4" style={{ width: 270 }}>
//                   <p>Marketing and Promotion</p>
//                   <span> Developing marketing strategies, creating promotional materials, and running campaigns to build awareness and reach audiences</span>
//                 </Card>  
//                 </div>
//             </div>
//         </div>
//      </div>
    
//   );
// }

// export default Infotabs


import { Card } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
function Infotabs() {
  return (
<div class="services-box ">
<Link to="/youtubeservices">
<div class="service ">
  <div class="flip-box">
    <div class="flip-box-inner">
      <div class="flip-box-front">
        <img src="img/icon3.jpg"/>
        <h3>YouTube CMS/MCN Services</h3>
        <Link to="/services" class="button button-primary mt-2">Read More </Link>
      </div>
      <div class="flip-box-back">
        <p>We always provide people a complete and fastest solution focused of your project.</p>
        
      </div>
    </div>
  </div>
</div>
</Link>
<Link to="/managmentservices">
<div class="service">
  <div class="flip-box">
    <div class="flip-box-inner">
      <div class="flip-box-front">
        <img src="img/icon2.jpg"/>
        <h3>Channel Promotion & Management</h3>
        <Link to="/managmentservices" class="button button-primary mt-2">Read More </Link>
      </div>
      <div class="flip-box-back">
        <p>We are customer focused provide a best solution of business.</p>
      </div>
    </div>
  </div>
</div>
</Link>
<Link to="/audioservices">
<div class="service">
  <div class="flip-box">
    <div class="flip-box-inner">
      <div class="flip-box-front">
        <img src="img/icon1.jpg"/>
        <h3>Audio Video Distribution</h3>
        <Link to="/audioservices" class="button button-primary mt-2">Read More </Link>
      </div>
      <div class="flip-box-back">
        <p>Our success rate almost 100% - ensures goal of any type business .</p>
      </div>
    </div>
  </div>
</div>
</Link>
<Link to="/socialservices">
<div class="service">
  <div class="flip-box">
    <div class="flip-box-inner">
      <div class="flip-box-front">
        <img src="img/music1.png"/>
        <h3>Social Media Management</h3>
        <Link to="/socialservices" class="button button-primary mt-2">Read More </Link>
      </div>
      <div class="flip-box-back">
        <p>We always provide people a complete solution focused of any business.</p>
      </div>
    </div>
  </div>
</div>
</Link>
<Link to="/tuneservices">
<div class="service">
  <div class="flip-box">
    <div class="flip-box-inner">
      <div class="flip-box-front">
        <img src="img/15584.jpg"/>
        <h3>Caller Tunes</h3>
        <Link to="/tuneservices" class="button button-primary mt-2">Read More </Link>
      </div>
      <div class="flip-box-back">
        <p>We always provide people a complete solution focused of any business.</p>
      </div>
    </div>
  </div>
</div>
</Link>



</div>
  );
}

export default Infotabs