import { openDB } from 'idb';

const DB_NAME = 'UserDB';
const STORE_NAME = 'userStore';

export async function getDB() {
  return openDB(DB_NAME, 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: 'id' });
      }
    }
  });
}

// Save multiple values
export async function saveValues(values) {
  const db = await getDB();
  await db.put(STORE_NAME, { id: 'user-data', ...values });
}

// Retrieve values
export async function getValues() {
  const db = await getDB();
  const data = await db.get(STORE_NAME, 'user-data');
  return data || {}; // Return an empty object if no data is found
}

// Delete values
export async function deleteValues() {
  const db = await getDB();
  await db.delete(STORE_NAME, 'user-data');
}
