import React from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import Banner from './Banner';
import Banner1 from './Banner1';
import Infotabs from './Infotabs';
import Aboutsite from '../About/Aboutsite';
// import Services from './Services';
import WorkMethod from './Workmethod';
import Video from './Video';
import Network from './Network';
import Testimonial from './Testimonial';
function HomeComponent() {
  return (
    <div>
      <>
      <Header/>
      <Banner1/>
      <Infotabs/>
      {/* <WorkMethod/> */}
      <Aboutsite/>
      {/* <Services/> */}
      <Video/>
      <Banner/>
      <Network/>
      <Testimonial/>
      <Footer/>

    </>
    </div>
  );
}

export default HomeComponent;