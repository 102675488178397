// import React from "react";
// import Slider from "react-slick";

// export default function SimpleSlider() {
//   var settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 4, // Default number of slides to show
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 2000,
//     responsive: [
//       {
//         breakpoint: 1024, // Adjust these breakpoints as needed
//         settings: {
//           slidesToShow: 3,
//         }
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 2,
//         }
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//         }
//       }
//     ]
//   };
//   return (
//     <div  className='home container'>
//       <h2 className="gradient-text">Trending Videos</h2>
//     <Slider {...settings}>
//       <div>
//       <iframe  width="100%" height="315" src="https://www.youtube.com/embed/dDEaH4uU3rg?si=wtt27Z-qRO5NUHHZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
//       </div>
//       <div>
//       <iframe  width="100%" height="315" src="https://www.youtube.com/embed/dDEaH4uU3rg?si=wtt27Z-qRO5NUHHZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
//       </div>
//       <div>
//       <iframe  width="100%" height="315" src="https://www.youtube.com/embed/dDEaH4uU3rg?si=wtt27Z-qRO5NUHHZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
//       </div>
//       <div>
//       <iframe  width="100%" height="315" src="https://www.youtube.com/embed/dDEaH4uU3rg?si=wtt27Z-qRO5NUHHZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
//       </div>
//       <div>
//       <iframe  width="100%" height="315" src="https://www.youtube.com/embed/dDEaH4uU3rg?si=wtt27Z-qRO5NUHHZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
//       </div>
//       <div>
//       <iframe  width="100%" height="315" src="https://www.youtube.com/embed/dDEaH4uU3rg?si=wtt27Z-qRO5NUHHZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
//       </div>
//     </Slider>
//     </div>
//   );
// }

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

export default function SimpleSlider() {
  const serverUrl = "https://en.kkhs.in/";

  const [videoData, setVideoData] = useState([]);

  const getData = async () => {
    try {
      const response = await fetch(`${serverUrl}api/upload/get-video`, {
        method: "GET",
      });

      if (!response.ok) {
        alert("Please Try Again");
        return;
      }

      const data = await response.json();
      const allVideos = data.videos;
      setVideoData(allVideos);
    } catch (error) {
      console.error(error);
      alert("An error occurred. Please try again.");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  var settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="home container">
      <h2 className="gradient-text">Trending Videos</h2>
      <Slider {...settings}>
        {[...videoData].reverse().slice(0, 8).map((video, index) => {
          return (
            <div key={index}>
              <iframe
                width="100%"
                height="315"
                src={`https://www.youtube.com/embed/${video.videoLink}?si=4Q3JGf8knKIT1c6Y`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              {/* <span className="btnvideo button button-primary mt-2"><Link to ="videoplay">Play</Link></span> */}
            </div>
          )
        })}
      </Slider>
    </div>
  );
}
