import React from 'react';
function Banner() {
  return (
    <div>
<div class="about">
  <div class="aboutbanner">
    <h1 className='gradient-text about-manage'>Contact</h1>
  </div>
  </div>
</div>
   
  );
}

export default Banner;