import React from 'react';

function Aboutsite() {
  return (
    <div className='aboutsite'>
  <div className='container '>
        <div className='row '>
            <div className='col-lg-7 col-md-12'>
             <h4 className='gradient-text gradient-text1'>About</h4>
             {/* <h3>"Bainsla Music" is a distinguished music production company rooted in the vibrant culture of Rajasthan, India."</h3> */}
             <p>
             "<span className='perastyle'>Bainsla Music" is a distinguished music production company rooted in the vibrant culture of Rajasthan, India.</span> Specializing in the traditional<span className='perastyle'> Rajasthani genre of 'Rasiya' songs</span>, our company has been dedicated to preserving and promoting the rich musical heritage of Rajasthan for the past 9 years. Located at 59A Chanchan Nagar, Gokulpura, Jhotwara, Jaipur-302012, Bainsla Music serves as a cultural beacon, showcasing the depth and diversity of Rajasthani music to the world.
<br/>
Under the visionary leadership of directors <span className='perastyle'>Ajeet Bainsla and Aman Bainsla</span>, our company has carved a unique niche in the music industry. Our commitment lies in creating music that not only entertains but also deeply resonates with the essence of Rajasthani traditions and stories. At Bainsla Music, we strive to blend the old with the new, ensuring that the soulful melodies of Rasiya songs continue to thrive and captivate audiences globally.
<br/>
Join us in our journey at<span className='perastyle'> Bainsla Music</span>, where every note we produce is a tribute to the timeless beauty of Rajasthan's musical landscape.
             </p>
            </div>
            <div className='col-lg-5 col-md-12'>
            <img src="img/34982.jpg" style={{width:"100%"}}></img>
             {/* <img src="img/music4.jpg" style={{width:"100%"}}></img> */}
                
            </div>
        </div>
    </div>
  </div>
  );
}

export default Aboutsite