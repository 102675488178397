import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';

function Video() {
  const serverUrl = "https://en.kkhs.in/";
  const [videoData, setVideoData] = useState([]);

  const getData = async () => {
    try {
      const response = await fetch(`${serverUrl}api/upload/get-video`, {
        method: "GET",
      });

      if (!response.ok) {
        alert("Please Try Again");
        return;
      }

      const data = await response.json();
      const allVideos = data.videos;
      setVideoData(allVideos);
    } catch (error) {
      console.error(error);
      alert("An error occurred. Please try again.");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className='container videcontainer'>
      <div className='righttab'>
        <Tabs tabPosition="right">
          {[...videoData].reverse().map((video, index) => {
            return (
              <Tabs.TabPane
                tab={<img src={`https://img.youtube.com/vi/${video.videoLink}/maxresdefault.jpg`} alt={`Thumbnail ${index + 1}`} />}
                key={index}
              >
                <iframe
                  width="100%"
                  height="515"
                  src={`https://www.youtube.com/embed/${video.videoLink}?si=4Q3JGf8knKIT1c6Y`}
                  title={`YouTube video ${index + 1}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      </div>
      <div className='toptab'>
        <Tabs tabPosition="top">
          {[...videoData].reverse().map((video, index) => {
            return (
              <Tabs.TabPane
                tab={<img src={`https://img.youtube.com/vi/${video.videoLink}/maxresdefault.jpg`} alt={`Thumbnail ${index + 1}`} />}
                key={index}
              >
                <iframe
                  width="100%"
                  height="515"
                  src={`https://www.youtube.com/embed/${video.videoLink}?si=4Q3JGf8knKIT1c6Y`}
                  title={`YouTube video ${index + 1}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
}

export default Video;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Tabs } from 'antd';

// function Video() {
//   const [videos, setVideos] = useState([]);

//   useEffect(() => {

//     axios.get('/videos.txt')
//       .then(response => {

//         const videoUrls = response.data.split('\n');
//         setVideos(videoUrls);
//       })
//       .catch(error => console.error('Error loading video URLs:', error));
//   }, []);

//   return (
//     <div className='container videcontainer'>
//       <Tabs tabPosition="right">
//         {videos.map((video, index) => (
//           <Tabs.TabPane
//             tab={<img src={`img/hqdefault${index + 1}.jpg`} alt={`Thumbnail ${index + 1}`} />}
//             key={index}
//           >
//             <iframe
//               width="100%"
//               height="315"
//               src={video}
//               title={`YouTube video ${index + 1}`}
//               frameBorder="0"
//               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//               allowFullScreen
//             ></iframe>
//           </Tabs.TabPane>
//         ))}
//       </Tabs>
//     </div>
//   );
// }

// export default Video;



// import React, { useState } from 'react';
// import axios from 'axios';
// import Test from './Test';
// import { Tabs } from 'antd';

// function Video() {
//   const videos = [
//     'https://www.youtube.com/embed/DXzLhupbyyM?si=rqBEPUGjLJEW4_-7',
//     'https://www.youtube.com/embed/YKmTjVYMHTk?si=aJjHgAH29E6_0Mq-',
//     'https://www.youtube.com/embed/F3vRqq05TdQ?si=vE8jpYFcFKsAM6eV',
//     'https://www.youtube.com/embed/uPAjroz5ZeA?si=Oq7eC79DDxcheC1m',
//     'https://www.youtube.com/embed/XLqmL9cPN1E?si=sIuuviT6r25M-DDb',
//     'https://www.youtube.com/embed/dDEaH4uU3rg?si=wtt27Z-qRO5NUHHZ',

//     // Add more YouTube video URLs here
//   ];
//   return (
//     <div>
//       <div className='container videcontainer'>
//       <Tabs tabPosition="right">
//       {videos.map((video, index) => (
//         <Tabs.TabPane
//           tab={<img src={`img/hqdefault${index + 1}.jpg`} alt={`Thumbnail ${index + 1}`} />}
//           key={index}
//         >
//           <iframe
//             width="100%"
//             height="315"
//             src={video}
//             title={`YouTube video ${index + 1}`}
//             frameBorder="0"
//             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//             allowFullScreen
//           ></iframe>
//         </Tabs.TabPane>
//       ))}
//     </Tabs>
//       </div>
{/* <div className='container video'>
      <div className='row'>
        <div className='videomenu'>
        <iframe  width="100%" height="515" src="https://www.youtube.com/embed/dDEaH4uU3rg?si=wtt27Z-qRO5NUHHZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
       </div>
       <div className='videomenu1'>
        <ul>
          <li>
           <iframe  width="100%"  src="https://www.youtube.com/embed/dDEaH4uU3rg?si=wtt27Z-qRO5NUHHZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
         </li>
         <li>
           <iframe  width="100%"  src="https://www.youtube.com/embed/dDEaH4uU3rg?si=wtt27Z-qRO5NUHHZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
         </li>
         <li>
           <iframe  width="100%"  src="https://www.youtube.com/embed/dDEaH4uU3rg?si=wtt27Z-qRO5NUHHZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
         </li>
         <li>
           <iframe  width="100%"  src="https://www.youtube.com/embed/dDEaH4uU3rg?si=wtt27Z-qRO5NUHHZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
         </li>
         <li>
           <iframe  width="100%"  src="https://www.youtube.com/embed/dDEaH4uU3rg?si=wtt27Z-qRO5NUHHZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
         </li>
         </ul>
       </div>
      </div>
     </div> */}


// </div>
//   );
// }

// export default Video;