import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { saveValues } from './indexedDB';

function Signup() {
  const serverUrl = "https://en.kkhs.in/";
  const navigate = useNavigate();

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleData = async () => {
    try {
      const response = await fetch(`${serverUrl}api/auth/login`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      const data = await response.json();

      if (response.ok) {
        await saveValues({ token: data.token, userId: data.userId });
        alert("Hey, You Are Logged In");
        setUser({ email: "", password: "" });
        navigate("/admin");
      } else if (response.status === 400) {
        alert("Email Not Exists");
      } else {
        alert("Please Try Again");
      }
    } catch (error) {
      console.error(error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <div className='signupform'>
      <input type="radio" id="loginForm" name="formToggle" checked readOnly />

      <div className="wrapper" id="loginFormContent">
        <h1>Login</h1>

        <div className="input-box">
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={user.email}
            required
            onChange={handleChange}
          />
        </div>

        <div className="input-box">
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={user.password}
            required
            onChange={handleChange}
          />
        </div>

        <button className="btn" onClick={handleData}>Login</button>
      </div>
    </div>
  );
}

export default Signup;
