import React from 'react';
import { Link } from 'react-router-dom';

function ContactUs() {
  return (
    <div className='contact'>
        <div className='container'>
            <div className='row'>
            <section>
    
    <div class="section-header">
      <div class="container">
        <h2 className='gradient-text '>Contact Us</h2>
        <p>Stay connected with us through our social media channels for the latest updates, releases, and behind-the-scenes glimpses of our music production journey. Your support and feedback are invaluable to us as we continue to celebrate and propagate the rich musical heritage of Rajasthan.

For direct inquiries or to schedule a meeting, please feel free to use the contact information provided. Our team at Bainsla Music looks forward to hearing from y</p>
      </div>
    </div>
    
    <div class="container">
      <div class="row">
        
        <div class="contact-info">
          <div class="contact-info-item">
            <div class="contact-info-icon">
              <i class="fas fa-home"></i>
            </div>
            
            <div class="contact-info-content">
              <h4>Address</h4>
              <p> 59A Chanchan Nagar, Gokulpura,<br/> Jhotwara, Jaipur-302012, <br/>Rajasthan, India,</p>
            </div>
          </div>
          
          <div class="contact-info-item">
            <div class="contact-info-icon">
              <i class="fas fa-phone"></i>
            </div>
            
            <div class="contact-info-content">
              <h4>Phone</h4>
              <p><Link to="tel:+91-8696761606">+91 8696761606</Link></p>
            </div>
          </div>
          
          <div class="contact-info-item">
            <div class="contact-info-icon">
              <i class="fas fa-envelope"></i>
            </div>
            
            <div class="contact-info-content">
              <h4>Email</h4>
             <p> <Link to="mailto:ajeet@bainslamusic.com">ajeet@bainslamusic.com</Link></p>
            </div>
          </div>
        </div>
        
        <div class="contact-form">
          <form action="" id="contact-form">
            <h2>Send Message</h2>
            <div class="input-box">
              <input type="text" required="true" name=""/>
              <span>Full Name</span>
            </div>
            
            <div class="input-box">
              <input type="email" required="true" name=""/>
              <span>Email</span>
            </div>
            
            <div class="input-box">
              <textarea required="true" name=""></textarea>
              <span>Type your Message...</span>
            </div>
            
            <div class="input-box">
              <input type="submit" value="Send" name=""/>
            </div>
          </form>
        </div>
        
      </div>
    </div>
  </section>
  <p style={{color:"#fff"}}>Thank you for your interest in Bainsla Music. Let's keep the tradition of Rajasthani Rasiya music alive and thriving together!</p>

  
            </div>
        </div>
    </div>
    
  );
}

export default ContactUs;
