import React from "react";
import { Link } from "react-router-dom";

function Header() {
  return (
    <div className='finalheader ' >
      <div className="container">
<header class="header">
  <nav>
    <div class="logo">
      <Link to="/"><img src="img/tseries-logo.png"/></Link>
    </div>
    <input type="checkbox" id="menu-toggle"/>
    <label for="menu-toggle" class="menu-icon">&#9776;</label>
    <ul class="menu">
      <li><Link to="/">Home</Link></li>
      <li><Link to="/about">About</Link></li>
      <li><Link to="/services">services</Link></li>
      <li><Link to="/videos">Videos</Link></li>
      <li><Link to="/contact">Contact</Link></li>
    </ul>
  </nav>
</header>
    </div>
    </div>
  );
}

export default Header;