import React from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
function Serviceinfo4() {

  return (
    <div>
      <Header/>
  <div className='detailsteam'>
  <div className='container'>
    <div className='row'>
    <div className=''>
        <div className='teamimg'>
        <img src="img/15584.jpg" style={{width:"35%"}}/>
        </div>
     
    </div>
    <div className=''>

<h3 className='gradient-text'>Caller Tunes</h3>
<p>We always provide people a complete and fastest solution focused of your project.</p>
 <div className=''>
    <h3>Selection</h3>
    <p>Users can choose a caller tune from a library of available songs and audio clips provided by their mobile network operator or third-party service provider. The selection may include popular songs, sound effects, instrumental music, or customized messages.</p>

    <h3>Activation</h3>
    <p>Once a caller tune is selected, the user activates it through their mobile network operator or service provider. This may involve subscribing to a caller tunes service and paying a subscription fee or a one-time activation fee</p>

    <h3>Customization</h3>
    <p>Users may have options to customize their caller tunes, such as setting different tunes for different callers or assigning specific tunes to groups of contacts (e.g., family, friends, colleagues).</p>
   
    <h3>Subscription</h3>
    <p> Caller tunes services may be offered as part of a subscription package or as an add-on service with additional charges. Users may have the option to change their caller tunes periodically or opt for a default tune provided by their mobile network operator.</p>

    <h3>Compatibility</h3>
    <p>Caller tunes are typically compatible with mobile phones that support the feature and are connected to networks that offer caller tunes services. Callers will hear the selected tune only if their phone supports caller tunes and the user has activated the service.</p>

    
 </div>
    </div>
    </div>
  </div>
  </div>
  <Footer/>
</div>
   
  );
}

export default Serviceinfo4;

