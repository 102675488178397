
import React from 'react';
function Map() {
  return (
    <div>
<div class="Container">
  <div class="map">
    <div className='row'>
    <h1 className='gradient-text about-manage'>Address</h1>
    <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d14227.423837471772!2d75.70590160356005!3d26.939780505365864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s59A%20Chanchan%20Nagar%2C%20Gokulpura%2C%20Jhotwara%2C%20Jaipur-302012%2C%20Rajasthan%2C%20India%2C!5e0!3m2!1sen!2sin!4v1707484452334!5m2!1sen!2sin" width="600" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
  </div>
  </div>
  </div>
</div>
   
  );
}

export default Map;