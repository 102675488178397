import React from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import { ProgressBar } from 'react-bootstrap';
function Vicky() {
    const now = 60;
  return (
    <div>
        <Header/>
<div class="about">
  <div class="aboutbanner">
    <h1 className='gradient-text about-manage'>Vicky</h1>
    <p>Business Advisor</p>
  </div>
  </div>
  <div className='detailsteam'>
  <div className='container'>
    <div className='row'>
    <div className='col-lg-6 col-md-6 col-sm-12'>
        <div className='teamimg'>
        <img src="img/2.jpg" style={{width:"100%"}}/>
        </div>
        <div className='socialcontact'>
            <h4>
            Quick Contact
            </h4>
            <p>mali@bainslamusic.com</p>
        </div>
    </div>
    <div className='col-lg-6 col-md-6 col-sm-12'>
<p>Business Advisor</p>
<h3>Vicky Meena</h3>
<p>Podcasting operational change management inside of workflows to establish a framework. Taking seamless key performance indicators offline to maximise the long tail. Keeping your eye on the ball while performing a deep dive</p>
<h4>Professional Skills</h4>
<div className='row'>
    <div className='col-md-6  col-sm-12'>
    <p>Planning</p>
    <ProgressBar animated now={now} label={`${now}%`}  />
   </div>
   <div className='col-md-6  col-sm-12'>
    <p>Consulting</p>
    <ProgressBar animated now={now} label={`${now}%`}  />
   </div>
   <div className='col-md-6  col-sm-12'>
    <p>Management</p>
    <ProgressBar animated now={now} label={`${now}%`}  />
   </div>
   <div className='col-md-6  col-sm-12'>
    <p>Development</p>
    <ProgressBar animated now={now} label={`${now}%`}  />
   </div>
</div>

 <div className=''>
    <h3>Experience & Activities</h3>
    <p>Bring to the table win-win survival strategies to ensure proactive domination. At the end of the day, going forward, a new normal that has evolved from generation X is on the runway heading towards a streamlined cloud solution. User generated content in real-time will have multiple touchpoints for offshoring. Capitalize on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide with additional clickthroughs from DevOps. Nanotechnology immersion along the information highway will close the loop on focusing solely on the bottom line.
<br/>
Podcasting operational change management inside of workflows to establish a framework. Taking seamless key performance indicators offline to maximise the long tail. Keeping your eye on the ball while performing a deep dive on the start-up mentality to derive convergence on cross-platform integration.</p>
 </div>
    </div>
    </div>
  </div>
  </div>
  <Footer/>
</div>
   
  );
}

export default Vicky;

