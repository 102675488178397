import React from 'react';
import { Link } from 'react-router-dom';
const Team = () => {
// import { Carousel } from 'antd';
// // import 'antd/dist/antd.css'; // Import the Ant Design styles
// import { Card } from 'antd';
// import { Link } from 'react-router-dom';

// const { Meta } = Card;
// const Team = () => {
//   const contentStyle = {
//     // height: '160px',
//     color: '#fff',
//     lineHeight: '160px',
//     textAlign: 'center',
//     background: '#364d79',
//   };

//   return (
//     <div className='container team'>
    
//     <div className='headerslider'><h2 className='gradient-text'>Meet Our Team</h2></div>
//     <Carousel
//         slidesToShow={3}
//         autoplay
//         autoplaySpeed={3000}
//         className="teamslide"
//         responsive={[
//           {
//             breakpoint: 991,
//             settings: {
//               slidesToShow: 2,
//             },
//           },
//           {
//             breakpoint: 585,
//             settings: {
//               slidesToShow: 1,
//             },
//           },
//         ]}
//       >
     
//       <Link to="/ajeet">
//         <div>
//       <Card
//     hoverable
//     style={{ width: 300 }}
//     cover={<img alt="example" src="img/3.jpg" />}
//   >
//     <Meta title="Ajeet Bainsla" description="Director" />
//   </Card>
//       </div>
//       </Link>
//       <Link to="/ashish">
//       <div>
//       <Card
//     hoverable
//     style={{ width: 300 }}
//     cover={<img alt="example" src="img/1.jpg" />}
//   >
//     <Meta title="Ashish Bainsla" description="Business Manager" />
//   </Card>
//       </div>
//       </Link>
//       <Link to="/vicky">
//       <div>
//       <Card
//     hoverable
//     style={{ width: 300 }}
//     cover={<img alt="example" src="img/2.jpg" />}
//   >
//     <Meta title="Vicky Meena" description="Business Advisor" />
//   </Card>
//       </div>
//       </Link>
    
//       {/* <div>
//         <h3 style={contentStyle}><img src ="img/team/engineer-construction-site.jpg"/>
//         <span>Manager  :  Ally</span>
//         </h3>
//       </div>
//       <div>
//         <h3 style={contentStyle}><img src ="img/team/engineer-construction-site.jpg"/>
//         <span>Manager  :  Ally</span>
//         </h3>
//       </div>
//       <div>
//         <h3 style={contentStyle}><img src ="img/team/engineer-construction-site.jpg"/>
//         <span>Manager  :  Ally</span>
//         </h3>
//       </div>
//       <div>
//         <h3 style={contentStyle}><img src ="img/team/engineer-construction-site.jpg"/>
//         <span>Manager  :  Ally</span>
//        </h3>
//       </div>
//       <div>
//         <h3 style={contentStyle}><img src ="img/team/engineer-construction-site.jpg"/>
//         <span>Manager  :  Ally</span>
//         </h3>
//       </div>
//       <div>
//         <h3 style={contentStyle}><img src ="img/team/modern-businessman-with-folder.jpg"/>
//         <span>Manager  :  Ally</span>
//         </h3>
//       </div>
//       <div>
//         <h3 style={contentStyle}><img src ="img/team/modern-businessman-with-folder.jpg"/>
//         <span>Manager  :  Ally</span>
//         </h3>
//       </div>
//       <div>
//         <h3 style={contentStyle}><img src ="img/team/modern-businessman-with-folder.jpg"/>
//         <span>Manager  :  Ally</span>
//         </h3>
//       </div>
//       <div>
//         <h3 style={contentStyle}><img src ="img/team/modern-businessman-with-folder.jpg"/>
//         <span>Manager  :  Ally</span>
//         </h3>
//       </div>
//       <div>
//         <h3 style={contentStyle}><img src ="img/team/modern-businessman-with-folder.jpg"/>
//         <span>Manager  :  Ally</span>
//         </h3>
//       </div>
//       <div>
//         <h3 style={contentStyle}><img src ="img/team/modern-businessman-with-folder.jpg"/>
//         <span>Manager  :  Ally</span>
//         </h3>
//       </div> */}
//     </Carousel>
//     </div>

//   );
// };

// export default Team;

  return (

<div class="section-content pb-70">
    <div class="container">
        <div class="col-lg-12 col-12">
            <div class="section-title-wrap mb-5">
                <h4 class="section-title">Our Directors</h4>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                <div class="team-card">
                    <div class="team-card-img">
                        <img src="img/team/ajeet.jpg" alt="image"/>
                    </div>
                    <div class="team-card-text">
                        <h4><Link href="javascript:void(0)">Ajeet Bainsla</Link></h4>
                        <p>Founder &amp; Director</p>
                        <div class="team-social-icons">
                            <ul>
                                <li><Link href="javascript:void(0)"><i class="fab fa-facebook-f"></i></Link></li>
                                <li><Link href="javascript:void(0)"><i class="fab fa-linkedin-in"></i></Link></li>
                                <li><Link href="javascript:void(0)"><i class="fab fa-twitter"></i></Link></li>
                                <li><Link href="javascript:void(0)"><i class="fab fa-instagram"></i></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                <div class="team-card">
                    <div class="team-card-img">
                        <img src="img/team/shivlalbainsla.jpg" alt="image"/>
                    </div>
                    <div class="team-card-text">
                        <h4><Link href="javascript:void(0)">Shivlal Bainsla</Link></h4>
                        <p>Director</p>
                        <div class="team-social-icons">
                            <ul>
                                <li><Link href="javascript:void(0)"><i class="fab fa-facebook-f"></i></Link></li>
                                <li><Link href="javascript:void(0)"><i class="fab fa-linkedin-in"></i></Link></li>
                                <li><Link href="javascript:void(0)"><i class="fab fa-twitter"></i></Link></li>
                                <li><Link href="javascript:void(0)"><i class="fab fa-instagram"></i></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                <div class="team-card">
                    <div class="team-card-img">
                        <img src="img/team/Aman.jpg" alt="image"/>
                    </div>
                    <div class="team-card-text">
                        <h4><Link href="javascript:void(0)">Aman Bainsla</Link></h4>
                        <p>Director</p>
                        <div class="team-social-icons">
                            <ul>
                                <li><Link href="javascript:void(0)"><i class="fab fa-facebook-f"></i></Link></li>
                                <li><Link href="javascript:void(0)"><i class="fab fa-linkedin-in"></i></Link></li>
                                <li><Link href="javascript:void(0)"><i class="fab fa-twitter"></i></Link></li>
                                <li><Link href="javascript:void(0)"><i class="fab fa-instagram"></i></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-lg-12 col-12">
                <div class="section-title-wrap mb-5">
                    <h4 class="section-title">Our Members</h4>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="team-card">
                    <div class="team-card-img">
                        <img src="/img/team/pammy.jpg" alt="image"/>
                    </div>
                    <div class="team-card-text">
                        <h4><Link href="javascript:void(0)">Pammy khatana</Link></h4>
                        <p>Dancer</p>
                        <div class="team-social-icons">
                            <ul>
                                <li><Link href="javascript:void(0)"><i class="fab fa-facebook-f"></i></Link></li>
                                <li><Link href="javascript:void(0)"><i class="fab fa-linkedin-in"></i></Link></li>
                                <li><Link href="javascript:void(0)"><i class="fab fa-twitter"></i></Link></li>
                                <li><Link href="javascript:void(0)"><i class="fab fa-instagram"></i></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="team-card">
                    <div class="team-card-img">
                        <img src="/img/team/Ashish.jpg" alt="image"/>
                    </div>
                    <div class="team-card-text">
                        <h4><Link href="javascript:void(0)">
					                  Ashish Bainsla</Link></h4>
                        <p>Business Manager</p>
                        <div class="team-social-icons">
                            <ul>
                                <li><Link href="javascript:void(0)"><i class="fab fa-facebook-f"></i></Link></li>
                                <li><Link href="javascript:void(0)"><i class="fab fa-linkedin-in"></i></Link></li>
                                <li><Link href="javascript:void(0)"><i class="fab fa-twitter"></i></Link></li>
                                <li><Link href="javascript:void(0)"><i class="fab fa-instagram"></i></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="team-card">
                    <div class="team-card-img">
                        <img src="/img/team/vicky.jpg" alt="image"/>
                    </div>
                    <div class="team-card-text">
                        <h4><Link href="javascript:void(0)">
				               	Vicky Meena</Link></h4>
                        <p>Business Advisor</p>
                        <div class="team-social-icons">
                            <ul>
                                <li><Link href="javascript:void(0)"><i class="fab fa-facebook-f"></i></Link></li>
                                <li><Link href="javascript:void(0)"><i class="fab fa-linkedin-in"></i></Link></li>
                                <li><Link href="javascript:void(0)"><i class="fab fa-twitter"></i></Link></li>
                                <li><Link href="javascript:void(0)"><i class="fab fa-instagram"></i></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
         
            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="team-card">
                    <div class="team-card-img">
                        <img src="/img/team/Sandeep.jpg" alt="image"/>
                    </div>
                    <div class="team-card-text">
                        <h4><Link href="javascript:void(0)">
                        Sandeep verma</Link></h4>
                        <p>Video Editor</p>
                        <div class="team-social-icons">
                            <ul>
                                <li><Link href="javascript:void(0)"><i class="fab fa-facebook-f"></i></Link></li>
                                <li><Link href="javascript:void(0)"><i class="fab fa-linkedin-in"></i></Link></li>
                                <li><Link href="javascript:void(0)"><i class="fab fa-twitter"></i></Link></li>
                                <li><Link href="javascript:void(0)"><i class="fab fa-instagram"></i></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="team-card">
                    <div class="team-card-img">
                        <img src="/img/team/karn.jpg" alt="image"/>
                    </div>
                    <div class="team-card-text">
                        <h4><Link href="javascript:void(0)">
                        Karan chaudhary</Link></h4>
                        <p>Video Editor</p>
                        <div class="team-social-icons">
                            <ul>
                                <li><Link href="javascript:void(0)"><i class="fab fa-facebook-f"></i></Link></li>
                                <li><Link href="javascript:void(0)"><i class="fab fa-linkedin-in"></i></Link></li>
                                <li><Link href="javascript:void(0)"><i class="fab fa-twitter"></i></Link></li>
                                <li><Link href="javascript:void(0)"><i class="fab fa-instagram"></i></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
       
            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="team-card">
                    <div class="team-card-img">
                        <img src="/img/team/Rajesh.jpg" alt="image"/>
                    </div>
                    <div class="team-card-text">
                        <h4><Link href="javascript:void(0)">
                        Rajesh Rao</Link></h4>
                        <p>Video Editor</p>
                        <div class="team-social-icons">
                            <ul>
                                <li><Link href="javascript:void(0)"><i class="fab fa-facebook-f"></i></Link></li>
                                <li><Link href="javascript:void(0)"><i class="fab fa-linkedin-in"></i></Link></li>
                                <li><Link href="javascript:void(0)"><i class="fab fa-twitter"></i></Link></li>
                                <li><Link href="javascript:void(0)"><i class="fab fa-instagram"></i></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  );
 };

 export default Team;