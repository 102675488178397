import React from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';

import Services from '../Home/Services';
import Banner from './Banner';
import Serviceinfo from './Serviceinfo';
import Infotabs from '../Home/Infotabs';

function Service() {
  return (
    <div>
        <Header/>
        <Banner/>
        <Infotabs />
        <Serviceinfo/>
         {/* <Services/> */}
        <Footer/>
     </div>
  );
}

export default Service