import React from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import Banner from './Banner';
import Video from '../Home/Video';
import Videocard from './Videocard';
function Videos() {
  return (
    <div>
        <Header/>
        <Banner/>
        <Video/>
        <Videocard/>
        <Footer/>
     </div>
  );
}

export default Videos