import React from 'react';
import Slider from 'react-slick';
function Testimonial() {
    var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1, // Default number of slides to show
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
          };
  return (
    <div>
<div className="testimonial">
    <div className='container'>
    <div className='row'>
    <h1 className='gradient-text '>Testimonial</h1>
  
    <Slider {...settings}>
    <div className=''>
       <div className='abouttest'>
              <img src="img/3-2-1.jpg"></img>
              </div>
           
              <h3 className='gradient-text abouttest'><span>jhon</span></h3> 
       <p><span><i class="fa fa-quote-left"></i></span>"Discovering this music website has been an absolute game-changer for me. Not only does it provide a vast collection of music across genres, but the curated playlists are a lifesaver for finding new favorites. The user-friendly interface makes navigation a breeze, and the audio quality is top-notch. Whether I'm looking for soothing melodies to unwind or upbeat tunes to kickstart my day, this website has it all. I can't recommend it enough to fellow music enthusiasts!"<i class="fa fa-quote-right
"></i> </p>
</div>
<div className=''>
       <div className='abouttest'>
              <img src="img/3-2-1.jpg"></img>
              </div>
           
              <h3 className='gradient-text abouttest'><span>jhon</span></h3> 
       <p><span><i class="fa fa-quote-left"></i></span>"Discovering this music website has been an absolute game-changer for me. Not only does it provide a vast collection of music across genres, but the curated playlists are a lifesaver for finding new favorites. The user-friendly interface makes navigation a breeze, and the audio quality is top-notch. Whether I'm looking for soothing melodies to unwind or upbeat tunes to kickstart my day, this website has it all. I can't recommend it enough to fellow music enthusiasts!"<i class="fa fa-quote-right
"></i> </p>
</div>

<div className=''>
       <div className='abouttest'>
              <img src="img/3-2-1.jpg"></img>
              </div>
           
              <h3 className='gradient-text abouttest'><span>jhon</span></h3> 
       <p><span><i class="fa fa-quote-left"></i></span> &nbsp; &nbsp; "Discovering this music website has been an absolute game-changer for me. Not only does it provide a vast collection of music across genres, but the curated playlists are a lifesaver for finding new favorites. The user-friendly interface makes navigation a breeze, and the audio quality is top-notch. Whether I'm looking for soothing melodies to unwind or upbeat tunes to kickstart my day, this website has it all. I can't recommend it enough to fellow music enthusiasts!" &nbsp; &nbsp; <i class="fa fa-quote-right
"></i> </p>
</div>
<div className=''>
       <div className='abouttest'>
              <img src="img/3-2-1.jpg"></img>
              </div>
           
              <h3 className='gradient-text abouttest'><span>jhon</span></h3> 
       <p><span><i class="fa fa-quote-left"></i></span> &nbsp; &nbsp; "Discovering this music website has been an absolute game-changer for me. Not only does it provide a vast collection of music across genres, but the curated playlists are a lifesaver for finding new favorites. The user-friendly interface makes navigation a breeze, and the audio quality is top-notch. Whether I'm looking for soothing melodies to unwind or upbeat tunes to kickstart my day, this website has it all. I can't recommend it enough to fellow music enthusiasts!" &nbsp; &nbsp; <i class="fa fa-quote-right
"></i> </p>
</div>

</Slider>
    </div>
   
  </div>
  </div>
  </div>

   
  );
}

export default Testimonial;