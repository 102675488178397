import React from 'react';
function PrivacyComponent() {
  return (
    <div>
<div class="privacy">
  <div className='container'>
    <div className='row'>
    {/* <div class="images-al-set">
   <img class="img-fluid"src="images/coming-soon.png"/>
  </div> */}
  <h3 className='gradient-text'>Privacy Policy for Bainsla Music</h3>

<h4>Effective Date: 6 Fab 2024</h4>

<h4 className='gradient-text'>Welcome to Bainsla Music. Your privacy is of paramount importance to us. This Privacy Policy outlines the types of personal information we collect, how we use it, and the steps we take to ensure your data is handled securely.</h4>

<h4>1. Information Collection:</h4>
<p>We collect information to provide better services to all our users. The types of personal information we may collect include:</p>
<br/>
<p>Contact details such as name, email address, and phone number.
Demographic information like age, preferences, and interests.
Information related to your usage of our website, such as page views and access times.</p>
<h4>2. Use of Information:</h4>
<p>The information we collect is used in various ways, including:</p>

<p>To personalize your experience and meet your individual needs.</p>
<p>To improve our website, offerings, and customer service.</p>
<p>To process transactions and send periodic emails regarding your order or other products and services.</p>
<p>To comply with legal requirements and protect our company's rights.</p>
<h4>3. Data Protection:</h4>
<p>We implement a variety of security measures to maintain the safety of your personal information. These include encryption, firewalls, and secure server hosting. However, no method of transmission over the Internet or electronic storage is 100% secure, so we cannot guarantee its absolute security.</p>

<h4>4. Information Sharing:</h4>
<p>We do not sell, trade, or otherwise transfer your personally identifiable information to outside parties without your consent, except to trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.
</p>
<h4>5. Cookies:</h4>
<p>Our website may use "cookies" to enhance user experience. Your web browser places cookies on your hard drive for record-keeping purposes and sometimes to track information about you. You may choose to set your web browser to refuse cookies or to alert you when cookies are being sent.</p>

<h4>6. Third-Party Links:</h4>
<p>Occasionally, at our discretion, we may include or offer third-party products or services on our website. These third-party sites have separate and independent privacy policies. We, therefore, have no responsibility or liability for the content and activities of these linked sites.</p>

<h4>7. Your Consent:</h4>
<p>By using our site, you consent to our website's Privacy Policy.</p>

<h4>8. Changes to our Privacy Policy:</h4>
<p>Any changes to our Privacy Policy will be posted on this page. The policy change will apply only to information collected after the date of the change.</p>

<h4>9. Contact Us:</h4>9. Contact Us:
<p>If you have any questions regarding this Privacy Policy, you may contact us using the information below:</p>

<h4>Address:</h4> 
<p>59A Chanchan Nagar, Gokulpura, Jhotwara, Jaipur-302012, Rajasthan, India</p>
<h4>Email: ajeet@bainslamusic.com</h4>
<h4>Phone: +918696761606</h4>
    </div>
  </div>

  </div>
</div>
   

  );
}

export default PrivacyComponent;