// import React from 'react';
// import { Tabs } from 'antd';

// function Test() {
//   return (
//     <Tabs tabPosition="right">
//       {[1, 2, 3,4,5].map((id) => (
//         <Tabs.TabPane
//           tab={<img src={`img/hqdefault${id}.jpg`} alt={`Thumbnail ${id}`} />}
//           key={id}
//         >
//           <iframe
//             width="100%"
//             height="315"
//             src={`https://www.youtube.com/embed/video_id_${id}`}
//             title={`YouTube video ${id}`}
//             frameBorder="0"
//             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//             allowFullScreen
//           ></iframe>
//         </Tabs.TabPane>
//       ))}
//     </Tabs>
//   );
// }

// export default Test;


// import React from 'react';
// import { Tabs } from 'antd';

// function Test() {
//   const videos = [
//     'https://www.youtube.com/embed/DXzLhupbyyM?si=rqBEPUGjLJEW4_-7',
//     'https://www.youtube.com/embed/YKmTjVYMHTk?si=aJjHgAH29E6_0Mq-',
//     'https://www.youtube.com/embed/F3vRqq05TdQ?si=vE8jpYFcFKsAM6eV',
//     'https://www.youtube.com/embed/uPAjroz5ZeA?si=Oq7eC79DDxcheC1m',
//     'https://www.youtube.com/embed/XLqmL9cPN1E?si=sIuuviT6r25M-DDb',
//     'https://www.youtube.com/embed/dDEaH4uU3rg?si=wtt27Z-qRO5NUHHZ',

//     // Add more YouTube video URLs here
//   ];

//   return (
//     <Tabs tabPosition="right">
//       {videos.map((video, index) => (
//         <Tabs.TabPane
//           tab={<img src={`img/hqdefault${index + 1}.jpg`} alt={`Thumbnail ${index + 1}`} />}
//           key={index}
//         >
//           <iframe
//             width="100%"
//             height="315"
//             src={video}
//             title={`YouTube video ${index + 1}`}
//             frameBorder="0"
//             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//             allowFullScreen
//           ></iframe>
//         </Tabs.TabPane>
//       ))}
//     </Tabs>
//   );
// }

// export default Test;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Tabs } from 'antd';

function Test() {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    // ध्यान दें कि 'videos.txt' का पथ आपकी परियोजना संरचना के अनुसार बदल सकता है
    axios.get('/videos.txt')
      .then(response => {
        // यह मानता है कि प्रत्येक URL नई पंक्ति पर है
        const videoUrls = response.data.split('\n');
        setVideos(videoUrls);
      })
      .catch(error => console.error('Error loading video URLs:', error));
  }, []);

  return (
    <div className='container videcontainer'>
      <Tabs tabPosition="right">
        {videos.map((video, index) => (
          <Tabs.TabPane
            tab={<img src={`img/hqdefault${index + 1}.jpg`} alt={`Thumbnail ${index + 1}`} />}
            key={index}
          >
            <iframe
              width="100%"
              height="315"
              src={video}
              title={`YouTube video ${index + 1}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
}

export default Test;
