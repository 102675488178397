import logo from './logo.svg';
import './App.css';
import './Admin.css'
import './fancybox.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import HomeComponent from './Component/Home/Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from './Component/About/About';
import 'bootstrap/dist/css/bootstrap.min.css';
import Videos from './Component/Videos/Videos';
import Service from './Component/Service/Service';
import Contact from './Component/Contact/Contact';
import Terms from './Component/Terms/Terms';
import Privacy from './Component/Privacy/Privacy';
import Test from './Component/Home/Test';
import Ajeet from './Component/Team/Ajeet';
import Vicky from './Component/Team/Vicky';
import Serviceinfo1 from './Component/Service/Serviceinfo1';
import Serviceinfo2 from './Component/Service/Serviceinfo2';
import Serviceinfo3 from './Component/Service/Serviceinfo3';
import Serviceinfo4 from './Component/Service/Serviceinfo4';
import Services from './Component/Home/Services';
import MainContainer from './admin/Dashbord';
import Signup from './admin/Signup';
import SignIn from './admin/signIn';
import ForgetPass from './admin/ForgetPassword';







function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomeComponent />} />
          <Route path="/about" element={<About />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/services" element={<Service />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/test" element={<Test />} />
          <Route path="/vicky" element={<Vicky />} />
          <Route path="/ajeet" element={<Ajeet />} />
          <Route path="/youtubeservices" element={<Services />} />
          <Route path="/managmentservices" element={<Serviceinfo1 />} />
          <Route path="/audioservices" element={<Serviceinfo2 />} />
          <Route path="/socialservices" element={<Serviceinfo3 />} />
          <Route path="/tuneservices" element={<Serviceinfo4 />} />
          <Route path="/admin" element={<MainContainer />} />
          <Route path="/admin/login" element={<Signup />} />
          <Route path="/admin/register" element={<SignIn />} />
          {/* <Route path="/admin/forget-password" element={<ForgetPass />} /> */}
        </Routes>
      </BrowserRouter>
    </div>

  );
}

export default App;


